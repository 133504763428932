import styled from '@emotion/styled'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'

import colorMap from 'src/utils/colorMap'

const { Text } = Typography

const BandwidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => p.theme.palette.neutral[100]};
  border-bottom: 1px solid #eee;
`

const BandwidthCard = styled.div`
  padding: 16px 16px 16px;
  width: 100%;

  display: flex;
  flex-direction: column;

  transition: all 0.2s;
`

const Value = styled.span`
  margin-top: 0;
  font-weight: 900;
  font-size: 26px;
  color: ${(p) => p.color};
  margin-right: 8px;
`

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const TypeText = styled(Text)`
  font-size: 10px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 16px;
  }

  span {
    font-size: 10px;
    margin-top: 8px;
  }
`

const Divider = styled.div`
  height: 48px;
  width: 1px;
  background: #eee;
  margin: 0 16px;
`

type BandwidthCardProps = {
  downloadSpeed?: number
  uploadSpeed?: number
  downloadColor: string
  uploadColor: string
}

const BandwidthScore = ({
  downloadSpeed,
  uploadSpeed,
  downloadColor,
  uploadColor,
}: BandwidthCardProps) => {
  const { t } = useTranslation()
  let bandwidthDown
  let bandwidthUp

  if (downloadSpeed) bandwidthDown = downloadSpeed / (1000 * 1000)
  if (uploadSpeed) bandwidthUp = uploadSpeed / (1000 * 1000)

  return (
    <BandwidthContainer>
      <BandwidthCard className="bandwidth-card">
        <TypeText>{t('signal_test.bw_down').cap()}</TypeText>
        <Content>
          <Value color={colorMap[downloadColor]}>
            {!isNaN(Number(bandwidthDown)) && (
              <CountUp
                end={Number(bandwidthDown)}
                duration={0.5}
                decimals={2}
              />
            )}
          </Value>
          <IconContainer>
            <FontAwesomeIcon icon={['fas', 'arrow-alt-down']} />
            <span>{t('iot.mbps').cap()}</span>
          </IconContainer>
        </Content>
      </BandwidthCard>
      <Divider />
      <BandwidthCard className="bandwidth-card">
        <TypeText>{t('signal_test.bw_up').cap()}</TypeText>
        <Content>
          <Value color={colorMap[uploadColor]}>
            {!isNaN(Number(bandwidthUp)) && (
              <CountUp end={Number(bandwidthUp)} duration={0.5} decimals={2} />
            )}
          </Value>
          <IconContainer>
            <FontAwesomeIcon icon={['fas', 'arrow-alt-up']} />
            <span>{t('iot.mbps').cap()}</span>
          </IconContainer>
        </Content>
      </BandwidthCard>
    </BandwidthContainer>
  )
}

export default BandwidthScore
