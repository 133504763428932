import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 56px);
`

export const LoadingPage = ({ title }: any) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Spin size="large" style={{ marginBottom: 16 }} />
      {title || t('status.loading')}
    </Container>
  )
}
