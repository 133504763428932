import { useEffect } from 'react'
import styled from '@emotion/styled'
import useUrlState from '@ahooksjs/use-url-state'
import LogRocket from 'logrocket'
import { useNavigate, useLocation } from 'react-router-dom'

import Nav from './Nav'

const Container = styled.div`
  width: 100%;
  height: 100vh;
`

const Layout = ({ children }: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [{ project_uid, serial_number }] = useUrlState()

  const pathName = location.pathname

  useEffect(() => {
    if (pathName.includes('/dashboard')) {
      // if there's no project UID or serial #, go to login page
      if (!project_uid || !serial_number) {
        navigate('/')
      }
    }
  }, [project_uid, serial_number, navigate, pathName])

  useEffect(() => {
    if (project_uid && serial_number) {
      LogRocket.identify(`${project_uid}/${serial_number}`, {
        project_uid,
        serial_number,
      })
    }
  }, [project_uid, serial_number])

  return (
    <Container>
      {pathName !== '/' && <Nav />}
      <div>{children}</div>
    </Container>
  )
}

export default Layout
