import * as React from 'react'

// creates a "superProvider" to avoid "WrapperHell"
const composeWrappers = (
  wrappers: React.FunctionComponent[]
): React.FunctionComponent =>
  wrappers.reduce(
    (Acc, Current): React.FunctionComponent =>
      (props) =>
        (
          <Current>
            <Acc {...props} />
          </Current>
        )
  )

export default composeWrappers
