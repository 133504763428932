import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faDown as fasDown,
  faUp as fasUp,
  faChevronLeft as fasChevronLeft,
  faSearch as fasSearch,
} from '@fortawesome/pro-solid-svg-icons'

// duoTone
import {
  faBoxCheck as fadBoxCheck,
  faBroadcastTower as fadBroadcastTower,
  faDownload as fadDownload,
  faLanguage as fadLanguage,
  faRouter as fadRouter,
  faTachometerAltFast as fadTachometerAltFast,
  faThermometerHalf as fadThermometerHalf,
  faUpload as fadUpload,
  faSignalAlt3 as fadSignalAlt3,
  faSimCard as fadSimCard,
  faSortAlt as fadSortAlt,
  faWaveformPath as fadWaveformPath,
  faWaveSine as fadWaveSine,
  faWaveSquare as fadWaveSquare,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  // solid
  fasDown,
  fasUp,
  fasChevronLeft,
  fasSearch,
  // DuoTone
  fadBoxCheck,
  fadBroadcastTower,
  fadDownload,
  fadLanguage,
  fadRouter,
  fadTachometerAltFast,
  fadThermometerHalf,
  fadUpload,
  fadSignalAlt3,
  fadSimCard,
  fadSortAlt,
  fadWaveformPath,
  fadWaveSine,
  fadWaveSquare
)
