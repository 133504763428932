import theme from 'src/lib/theme'

const colorMap: any = {
  green: theme.palette.success[600],
  yellow: theme.palette.warning[600],
  red: theme.palette.danger[600],
  undefined: theme.palette.neutral[600],
}

export default colorMap
