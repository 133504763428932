import { useEffect } from 'react'
import { Result } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'src/queries'
import { LoadingPage } from 'src/ui-components'

const QRCodePage = () => {
  const { t } = useTranslation()
  const { project_uid, serial_number }: any = useParams()
  const navigate = useNavigate()
  const { data, error, isLoading, isError } = useHistory({
    project_uid,
    serial_number,
  })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        navigate(
          `/dashboard?project_uid=${project_uid}&serial_number=${serial_number}`
        )
      } else if (error) {
        window.setTimeout(() => {
          navigate('/')
        }, 2000)
      }
    }
  }, [error, isLoading, data, navigate, project_uid, serial_number])

  return (
    <div>
      {isError && (
        <Result
          status="error"
          title={t('message.error.could_not_find_device')}
          subTitle={`${t('status.redirecting_to_home_page')}...`}
        />
      )}
      {isLoading && (
        <LoadingPage title={t('message.loading.looking_up_device')} />
      )}
    </div>
  )
}

export default QRCodePage
