import { useEffect } from 'react'
import { Result } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useQRCodeLegacy } from 'src/queries'
import { LoadingPage } from 'src/ui-components'

const QRLegacy = () => {
  const { t } = useTranslation()
  const params: any = useParams()
  const navigate = useNavigate()
  const { data, error, isLoading, isError } = useQRCodeLegacy({
    qr_code: params.qr_code,
  })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        navigate(
          `/dashboard?project_uid=${data.signaltest_project_uid}&serial_number=${data.serial_number}`
        )
      } else if (error) {
        window.setTimeout(() => {
          navigate('/')
        }, 2000)
      }
    }
  }, [error, isLoading, data, navigate])

  return (
    <div>
      {isError && (
        <Result
          status="error"
          title={t('message.error.could_not_find_device')}
          subTitle={`${t('status.redirecting_to_home_page')}...`}
        />
      )}
      {isLoading && (
        <LoadingPage title={t('message.loading.looking_up_device')} />
      )}
    </div>
  )
}

export default QRLegacy
