import { useState } from 'react'
import { Button, message } from 'antd'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import useUrlState from '@ahooksjs/use-url-state'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { useActive, useRunTest } from 'src/queries'

const RunTestButton = styled(Button)`
  border-radius: 4px;
`

type TestResponseType = {
  created: boolean
  error: any
  id: string
}

const RunSignalTest = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ project_uid, serial_number }] = useUrlState()
  const [testInProgress, setTestInprogress] = useState(false)

  const { data } = useActive({
    project_uid,
    serial_number,
  })

  const { mutate } = useRunTest({
    project_uid,
    serial_number,
  })

  const runTest = () => {
    setTestInprogress(true)
    message.info(t('message.loading.starting_signal_test').cap())
    mutate(undefined, {
      onSuccess: (response: TestResponseType) => {
        if (response.error) {
          message.error(
            t('message.error.signal_test_already_in_progress').cap()
          )
        } else {
          message.info(t('message.loading.signal_test_in_progress').cap())
          navigate(
            `/dashboard/test/${response.id}?${queryString.stringify({
              project_uid,
              serial_number,
            })}`
          )
        }
        setTestInprogress(false)
      },
      onError: (err) => {
        console.log('🔈 ~ err', err)
        message.error('Signal test failed')
        setTestInprogress(false)
      },
    })
  }

  if (!data?.active) return null

  return (
    <RunTestButton
      block
      size="large"
      type="primary"
      onClick={runTest}
      disabled={testInProgress}
    >
      <FontAwesomeIcon
        icon={['fad', 'tachometer-alt-fast']}
        style={{ marginRight: 8 }}
      />
      {t('action.run_signal_test').cap()}
    </RunTestButton>
  )
}

export default RunSignalTest
