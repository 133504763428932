import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import CountUp from 'react-countup'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import colorMap from 'src/utils/colorMap'

const Container = styled.div`
  height: ${(p: any) => p.height}px;
  width: ${(p: any) => p.height}px;

  .CircularProgressbar-text {
    padding-top: 8px !important;
  }
`

const ScoreText = styled.div`
  color: ${(p: { scoreColor: string }) => colorMap[p.scoreColor]};
  font-weight: bold;
  font-size: ${(p: any) => p.fontSize}px;
`

type ScoreProps = {
  score: number
  scoreColor: string
  height?: number
  fontSize?: number
  suffix?: string
  trailColor?: string
}

export const Score = ({
  score,
  scoreColor,
  height = 48,
  fontSize = 20,
  suffix = '',
  trailColor,
}: ScoreProps) => {
  const [value, setValue] = useState(0)
  const theme: any = useTheme()

  useEffect(() => {
    window.setTimeout(() => {
      setValue(score)
    }, 10)
  }, [score])

  return (
    // @ts-ignore
    <Container className="circular-progress-bar" height={height}>
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: colorMap[scoreColor] || scoreColor,
          textColor: scoreColor || theme.palette.neutral[800],
          trailColor: trailColor || theme.palette.neutral[400],
          backgroundColor: '#000',
        })}
      >
        {/* @ts-ignore */}
        <ScoreText scoreColor={scoreColor} fontSize={fontSize}>
          <strong>
            <CountUp end={value} duration={0.5} />
            {suffix}
          </strong>
        </ScoreText>
      </CircularProgressbarWithChildren>
    </Container>
  )
}
