import { locale as componentsLocale } from '@colonynetworks/ui'

const englishMessages = {
  translation: {
    ...componentsLocale.frTranslations,
    document: {
      title: 'Colony Networks',
      favicon: '/favicon/favicon.ico',
      copyright: '©{{year}}, Colony Networks Inc.',
      powered_by: 'Propulsé par',
      colony_networks: 'Colony Networks',
    },
    common: {
      signal_test: 'Test de signaux',
    },
    marketing: {
      improve_performance: 'Améliorez les performances de votre appareil LTE',
    },
    action: {
      back: 'retour',
      run_signal_test: 'exécuter le test du signal',
      find_item: 'trouver {{item}}',
      find_other_device: 'trouver un autre appareil',
      retry: 'réessayer',
    },
    status: {
      online: 'en ligne',
      offline: 'hors ligne',
      active: 'actif',
      inactive: 'inactif',
      loading: 'chargement',
      redirecting_to_home_page: "redirection vers la page d'accueil",
      passed: undefined,
      passed_with_caution: undefined,
      failed: undefined,
    },
    iot: {
      device: 'appareil',
      site: 'site',
      project_uid: 'ID du projet',
      serial_number: 'numéro de série',
      download_speed: 'vitesse de téléchargement',
      upload_speed: "vitesse d'envoi",
      mbps: 'Mbps',
    },
    locale: {
      english: 'English',
      french: 'Français',
    },
    signal_test: {
      signal_test_history: 'historique des tests de signaux',
      connection_type: 'type de connexion',
      jitter: 'gigue',
      latency: 'latence',
      noise: 'bruit',
      band: 'Canal (bande)',
      cell_id: 'ID de cellule',
      packet_loss: 'perte de paquets',
      signal: 'force du signal',
      temp: 'température',
      bw_down: 'vitesse de téléchargement',
      bw_up: "vitesse d'envoi",
      status: {
        'collecting-bandwidth': 'mesure de la bande passante',
        'created-scheduled-to-run': 'test du signal programmé à exécuter',
        'test-failed': 'échec du test',
        'calculating-score': 'calcul du score',
        'collecting-stats': 'collecte des statistiques',
        'collecting-latency': 'mesure de la latence',
        'failed-to-create-record-in-database':
          'échec de la création dans la base de données',
        'failed-to-start-process': 'échec du démarrage du processus',
        complete: 'terminé',
      },
    },
    message: {
      loading: {
        starting_signal_test: 'démarrage du test du signal',
        signal_test_in_progress: 'test du signal en cours',
        looking_up_device: "recherche de l'appareil",
      },
      error: {
        project_id_or_serial_not_found:
          'ID du projet ou numéro de série non trouvé',
        could_not_find_device: "impossible de trouver l'appareil",
        signal_test_already_in_progress: 'test du signal déjà en cours',
        failed_fetching_history: "échec de la récupération de l'historique",
      },
      validation: {
        missing_item: 'Veuillez entrer {{item}}',
        invalid_item: '{{item}} non valide',
      },
    },
  },
}

export default englishMessages
