import useUrlState from '@ahooksjs/use-url-state'
import { Result, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'

import { useHistory } from 'src/queries'
import HistoryLoader from './HistoryLoader'

import { HistoryRecord } from '@colonynetworks/ui'

const History = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ project_uid, serial_number }] = useUrlState()
  const { data, isLoading, error, isError, refetch } = useHistory({
    project_uid,
    serial_number,
  })

  if (isLoading) return <HistoryLoader />

  const handleClick = (id: string) => {
    const params = queryString.stringify({ project_uid, serial_number })
    navigate(`test/${id}?${params}`)
  }

  return (
    <div style={{ padding: 24, paddingTop: 8 }}>
      {isError && (
        <Result
          status="error"
          title={t('message.error.failed_fetching_history').cap()}
          // @ts-ignore
          subTitle={error?.message}
          extra={
            <Button type="primary" onClick={() => refetch()}>
              {t('action.retry').cap()}
            </Button>
          }
        />
      )}
      {data?.records.map((record: any) => (
        <HistoryRecord record={record} key={record._id} onClick={handleClick} />
      ))}
    </div>
  )
}

export default History
