import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { t } from 'i18next'

import colorMap from 'src/utils/colorMap'

const BandwidthContainer = styled.div`
  padding: 16px 16px;
  background: ${(p: any) => p.theme.palette.neutral[200]};
  border-radius: 8px;
  color: ${(p: { type: any; color: string }) => p.color};

  font-size: 12px;
  font-weight: 500;

  svg {
    margin-left: 8px;
  }

  @media (max-width: ${(p: any) => p.theme.breakpoints.xxs}) {
    font-size: 10px;
  }
`

const BandwidthText = styled.span`
  overflow: hidden;
  white-space: nowrap;
`

type BandwidthIndicatorProps = {
  speed?: number
  type: 'download' | 'upload'
  color: string
}

const iconMap: any = {
  download: 'arrow-alt-down',
  upload: 'arrow-alt-up',
}

export const BandwidthIndicator = ({
  speed,
  type,
  color,
}: BandwidthIndicatorProps) => {
  let speedInMbps = 0
  if (speed) {
    speedInMbps = speed / (1000 * 1000)
  }

  return (
    <Tooltip title={t(`iot.${type}_speed`).cap()}>
      {/* @ts-ignore */}
      <BandwidthContainer
        type={type}
        color={colorMap[color]}
        className="bandwidth-indicator"
      >
        <BandwidthText>{speedInMbps.toFixed(0)} Mbps</BandwidthText>
        <FontAwesomeIcon icon={['fas', iconMap[type]]} />
      </BandwidthContainer>
    </Tooltip>
  )
}
