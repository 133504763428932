import styled from '@emotion/styled'
import useUrlState from '@ahooksjs/use-url-state'

import { useActive, useHistory } from 'src/queries'
import RunSignalTest from './RunSignalTest'
import { DeviceCard, DeviceStatus } from '@colonynetworks/ui'

const DeviceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 16px 24px;

  .device-card {
    margin-bottom: 16px;
  }
`

const Device = () => {
  const [{ project_uid, serial_number }] = useUrlState()

  const { data: historyData } = useHistory({
    project_uid,
    serial_number,
  })
  const { data, isLoading } = useActive({
    project_uid,
    serial_number,
  })

  const lastTest = historyData?.records[0] || {}

  return (
    <DeviceContainer>
      <DeviceStatus active={data?.active} isLoading={isLoading} />
      <DeviceCard
        active={data?.active}
        siteName={lastTest?.site_name}
        serialNumber={serial_number}
      />
      <RunSignalTest />
    </DeviceContainer>
  )
}

export default Device
