import { locale as componentsLocale } from '@colonynetworks/ui'

const englishMessages = {
  translation: {
    ...componentsLocale.enTranslations,
    document: {
      title: 'Colony Networks',
      favicon: '/favicon/favicon.ico',
      copyright: '©{{year}}, Colony Networks Inc.',
      powered_by: 'Powered by',
      colony_networks: 'Colony Networks',
    },
    common: {
      signal_test: 'Signal Test',
    },
    marketing: {
      improve_performance: 'Improve performance of your LTE device',
    },
    action: {
      back: 'back',
      run_signal_test: 'run signal test',
      find_item: 'find {{item}}',
      find_other_device: 'find other device',
      retry: 'retry',
    },
    status: {
      online: 'online',
      offline: 'offline',
      active: 'active',
      inactive: 'inactive',
      loading: 'loading',
      redirecting_to_home_page: 'redirecting to home page',
      passed: 'passed',
      passed_with_caution: 'passed with caution',
      failed: 'failed',
    },
    iot: {
      device: 'device',
      site: 'site',
      project_uid: 'project ID',
      serial_number: 'serial #',
      download_speed: 'download speed',
      upload_speed: 'upload speed',
      mbps: 'Mbps',
    },
    locale: {
      english: 'English',
      french: 'Français',
    },
    signal_test: {
      signal_test_history: 'signal test history',
      connection_type: 'connection type',
      jitter: 'jitter',
      latency: 'latency',
      noise: 'noise',
      band: 'Channel (band)',
      cell_id: 'cell ID',
      packet_loss: 'packet loss',
      signal: 'signal strength',
      temp: 'temperature',
      bw_down: 'download speed',
      bw_up: 'upload speed',
      status: {
        'collecting-bandwidth': 'measuring bandwidth',
        'created-scheduled-to-run': 'signal test scheduled to run',
        'test-failed': 'test failed',
        'calculating-score': 'calculating score',
        'collecting-stats': 'collecting stats',
        'collecting-latency': 'measuring latency',
        'failed-to-create-record-in-database':
          'failed to create record in database',
        'failed-to-start-process': 'failed to start process',
        complete: 'complete',
      },
    },
    message: {
      loading: {
        starting_signal_test: 'starting signal test',
        signal_test_in_progress: 'signal test in progress',
        looking_up_device: 'looking up device',
      },
      error: {
        project_id_or_serial_not_found:
          'project ID or serial number is not found',
        could_not_find_device: 'could not find device',
        signal_test_already_in_progress: 'signal test already in progress',
        failed_fetching_history: 'failed fetching history',
      },
      validation: {
        missing_item: 'Please input {{item}}',
        invalid_item: 'invalid {{item}}',
      },
    },
  },
}

export default englishMessages
