import React from 'react'
import ReactDOM from 'react-dom'

import Routes from './Routes'
import reportWebVitals from './reportWebVitals'
import AppProvider from 'src/lib/AppProvider'

import 'antd/dist/antd.min.css'
import 'src/lib/index.css'
import 'src/lib/prototypes'
import 'src/lib/logrocket'

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <Routes />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
