import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const Disclaimer = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${(p) => p.theme.palette.neutral[700]};
  padding-bottom: 32px;
`

const PoweredBy = () => {
  const { t } = useTranslation()

  return (
    <Disclaimer>
      {t('document.powered_by').cap()}{' '}
      <a href="https://colonynetworks.com" target="_blank" rel="noreferrer">
        {t('document.colony_networks')}
      </a>
    </Disclaimer>
  )
}

export default PoweredBy
