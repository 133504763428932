import { Spin, Typography } from 'antd'
import styled from '@emotion/styled'

const { Text } = Typography

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RecordContainer = styled.div`
  padding: 16px;
  width: 100%;

  border: solid 1px #eee;
  border-radius: 8px;
  background: ${(p) => p.theme.palette.neutral[100]};

  display: flex;
  align-items: center;
  flex-direction: column;
`

const HistoryLoader = () => {
  return (
    <Container>
      <RecordContainer>
        <Spin style={{ marginBottom: 8 }} />
        {/* TODO: translation */}
        <Text>Loading history</Text>
      </RecordContainer>
    </Container>
  )
}

export default HistoryLoader
