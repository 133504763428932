import { Layout, Button } from 'antd'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isPropValid from '@emotion/is-prop-valid'
import { useNavigate, useLocation } from 'react-router-dom'
import LocaleSelector from './LocaleSelector'

const { Header } = Layout

const Navbar = styled(Header, { shouldForwardProp: isPropValid })`
  position: relative;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.palette.neutral[100]};
  padding: 0;
  height: 56px;

  border-bottom: ${(p: { pathName: string }) =>
    p.pathName.includes('/test/') ? 'none' : '1px solid #eee'};
`

const Logo = styled.img`
  position: absolute;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const BackButton = styled(Button)`
  height: 100%;
  width: 56px;
  border: none;
`

const Nav = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleClick = () => {
    if (location.pathname === '/dashboard') {
      navigate('/')
    } else {
      navigate(`/dashboard${location.search}`)
    }
  }

  return (
    <Navbar pathName={location.pathname}>
      {location.pathname !== '/' && (
        <BackButton onClick={handleClick}>
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </BackButton>
      )}

      <Logo src="/logos/signaltest.png" />
      <LocaleSelector />
    </Navbar>
  )
}

export default Nav
