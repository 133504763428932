import { QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@emotion/react'

import composeWrappers from 'src/lib/composeWrappers'
import queryClient from 'src/queries/queryClient'
import theme from 'src/lib/theme'

// imports
import 'src/lib/locale'
import 'src/lib/iconLibrary'

// antd internationalization
import enUS from 'src/lib/locale/antd/en_US'
import frFR from 'src/lib/locale/antd/fr_FR'

type ProvidersProps = {
  children: React.ReactNode
}

const AppProvider = ({ children }: ProvidersProps) => {
  const { i18n } = useTranslation()
  // const theme = useStore((state: any) => state.theme)

  /* istanbul ignore next */
  const antDLocale = i18n.language === 'fr-CA' ? frFR : enUS

  const SuperProvider = composeWrappers([
    ({ children }) => (
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    ),
    ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>,
    ({ children }) => (
      <ConfigProvider locale={antDLocale}>{children}</ConfigProvider>
    ),
  ])

  return (
    <SuperProvider>
      <>{children}</>
    </SuperProvider>
  )
}

export default AppProvider
