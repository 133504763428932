import { useQuery } from 'react-query'
import axios from 'axios'
import queryString from 'query-string'

type dataType = {
  project_uid: string
  serial_number: string
}
export const useActive = (parameters: dataType) => {
  return useQuery(
    ['active', parameters.project_uid, parameters.serial_number],
    async () => {
      const params = queryString.stringify(parameters)

      try {
        const result = await axios.get(
          `https://api-signaltest.colonynetworks.com/v4/signaltest/active?${params}`
        )

        return result.data
      } catch (error) {
        throw new Error('Failed fetching active')
      }
    }
  )
}
