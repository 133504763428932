import { useQuery } from 'react-query'
import axios from 'axios'
import queryString from 'query-string'

type dataType = {
  project_uid: string
  serial_number: string
  test_id: string
}

export const useTestDetails = (parameters: dataType, options?: any) => {
  return useQuery(
    ['test-details', ...Object.values(parameters)],
    async () => {
      const params = queryString.stringify(parameters)

      try {
        const result = await axios.get(
          `https://api-signaltest.colonynetworks.com/v4/signaltest/test_details?${params}`
        )

        return result.data
      } catch (error) {
        return { error }
      }
    },
    options
  )
}
