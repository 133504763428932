import { useQuery } from 'react-query'
import axios from 'axios'
import queryString from 'query-string'

type dataType = {
  project_uid: string
  serial_number: string
}

export const historyFetch = async (parameters: dataType) => {
  const params = queryString.stringify(parameters)

  try {
    const result = await axios.get(
      `https://api-signaltest.colonynetworks.com/v4/signaltest/history?${params}`
    )

    return result.data
  } catch (error) {
    throw new Error('Failed fetching history')
  }
}

export const useHistory = (parameters: dataType, options?: any) => {
  return useQuery(
    ['history', parameters.project_uid, parameters.serial_number],
    () => historyFetch(parameters),
    options
  )
}
