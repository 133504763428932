import { useState } from 'react'
import { Input, Button, Form, Alert } from 'antd'
import LogRocket from 'logrocket'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import queryClient from 'src/queries/queryClient'
import queryString from 'query-string'
import { historyFetch } from 'src/queries'
import MarketingImage from './MarketingImage'
import PoweredBy from 'src/ui-components/PoweredBy'

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 32px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-form-item-label {
    padding: 0;
  }
`

const Logo = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`

const MarketingText = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutral[700]};
`

const Login = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      await queryClient.fetchQuery('history', () => historyFetch(values))

      const params: any = queryString.stringify(values)

      LogRocket.identify(`${params.project_uid}/${params.serial_number}`, {
        project_uid: params.project_uid,
        serial_number: params.serial_number,
      })

      navigate(`/dashboard?${params}`)
    } catch (err) {
      setIsError(true)
    }

    setIsLoading(false)
  }

  return (
    <>
      {isError && (
        <Alert
          message={t('message.error.project_id_or_serial_not_found').cap()}
          type="error"
          showIcon
        />
      )}
      <Container>
        <Logo src="/logos/signaltest.png" />
        <MarketingText>{t('marketing.improve_performance')}</MarketingText>
        <MarketingImage />
        <Form
          name="device_lookup"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          style={{ width: '100%' }}
        >
          <Form.Item
            name="project_uid"
            rules={[
              {
                required: true,
                message: t('message.validation.missing_item', {
                  item: t('iot.project_uid'),
                }).cap(),
              },
              {
                max: 40,
                message: t('message.validation.invalid_item', {
                  item: t('iot.project_uid'),
                }).cap(),
              },
            ]}
          >
            <Input
              placeholder={t('iot.project_uid').cap()}
              style={{ borderRadius: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="serial_number"
            rules={[
              {
                required: true,
                message: t('message.validation.missing_item', {
                  item: t('iot.serial_number'),
                }).cap(),
              },
              {
                max: 40,
                message: t('message.validation.invalid_item', {
                  item: t('iot.serial_number'),
                }).cap(),
              },
            ]}
          >
            <Input
              placeholder={t('iot.serial_number').cap()}
              style={{ borderRadius: 4 }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ borderRadius: 4 }}
            >
              {t('action.find_item', { item: t('iot.device') }).cap()}
            </Button>
          </Form.Item>
        </Form>
        <PoweredBy />
      </Container>
    </>
  )
}

export default Login
