import styled from '@emotion/styled'

import PoweredBy from 'src/ui-components/PoweredBy'
import Device from './Device'
import Chart from './Chart'
import History from './History'

const Container = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
`

const Dashboard = () => (
  <Container>
    <Device />
    <Chart />
    <History />
    <PoweredBy />
  </Container>
)

export default Dashboard
