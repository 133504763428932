import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// translation files
import en from './en'
import fr from './fr'

let englishMessages = en
let frenchMessages = fr

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-CA': englishMessages,
      'fr-CA': frenchMessages,
    },
    supportedLngs: ['en-CA', 'fr-CA'],
    fallbackLng: 'en-CA',
    interpolation: {
      escapeValue: false,
    },
  })
