import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { Score } from 'src/ui-components'

const Container = styled.div`
  transform: rotateX(31deg) rotateZ(6deg);
  transform-style: preserve-3d;
  border-radius: 24px;
  box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    0px 20px 28px 0 rgba(34, 33, 81, 0.25);
  transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;

  &:hover {
    transform: rotateX(0deg) rotateZ(0deg) scale(1.2);
    box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 0px 0 rgba(34, 33, 81, 0.01),
      0px 2px 28px 0 rgba(34, 33, 81, 0.25);
  }

  height: 100px;
  width: 100px;
  background: white;
  margin: 32px auto 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const MarketingImage = () => {
  const theme = useTheme()

  return (
    <Container>
      <Score score={87} scoreColor={theme.palette.info[500]} height={60} />
    </Container>
  )
}

export default MarketingImage
