import { Button, Menu, Dropdown } from 'antd'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'

const LocaleButton = styled(Button)`
  height: 100%;
  width: 56px;
  border: none;
  margin-left: auto;
`

const LocaleSelector = () => {
  const { t, i18n } = useTranslation()
  const handleMenuClick = (e: any) => {
    i18n.changeLanguage(e.key)
    dayjs.locale(e.key === 'en-CA' ? 'en' : 'fr')
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="en-CA">{t('locale.english').cap()}</Menu.Item>
      <Menu.Item key="fr-CA">{t('locale.french').cap()}</Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <LocaleButton onClick={(e) => e.preventDefault()}>
        <FontAwesomeIcon icon={['fad', 'language']} />
      </LocaleButton>
    </Dropdown>
  )
}

export default LocaleSelector
