import styled from '@emotion/styled'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme, keyframes } from '@emotion/react'
import dayjs from 'dayjs'
import CountUp from 'react-countup'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import colorMap from 'src/utils/colorMap'

const { Text } = Typography

const animatedDots = keyframes`
    0%, 20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%, 100% {
      content: '';
    }
`

const ScoreContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  .circular-progressbar-with-children {
    width: 120px;
  }
`

const DateText = styled(Text)`
  font-size: 12px;
  margin-bottom: 16px;
  color: ${(p: any) => p.theme.palette.neutral[700]};
`

const Status = styled.div`
  background-color: ${(p: {
    theme?: any
    isComplete: boolean
    scoreColor: string
  }) => {
    if (p.isComplete) return colorMap[p.scoreColor]
    else return p.theme.palette.primary[600]
  }};
  padding: 6px 16px;
  border-radius: 4px;
  color: white;

  font-weight: 500;
  font-size: 12px;
  margin-top: 12px;
  transition: all 0.5s;

  &:first-letter {
    text-transform: uppercase;
  }

  &&:after {
    animation: ${(p) => (!p.isComplete ? animatedDots : '')} 4s linear infinite;
    content: '';
  }
`

const ScoreTextContainer = styled.div`
  color: ${(p: { scoreColor: string }) => p.scoreColor};
  font-weight: bold;
  font-size: 32px;
`

const RecordScore = ({ record }: any) => {
  const theme: any = useTheme()
  const { t } = useTranslation()

  const isComplete = record?.status_complete

  const statusMessageArr = record?.status_message.split('.') || []
  const statusMessageKey = statusMessageArr[statusMessageArr.length - 1]
  const statusMessage = t(`@colony/ui.signal_test.status.${statusMessageKey}`)

  const date = record
    ? dayjs(record['@timestamp']).format('MMM D, YYYY h:mm A')
    : ''

  let score = record?.score
  if (isNaN(record?.score)) score = record?.progress
  const scoreColor = colorMap[record?.score_color] || record?.score_color

  return (
    <ScoreContainer>
      <DateText>{date}</DateText>
      <CircularProgressbarWithChildren
        className="circular-progressbar-with-children"
        value={score}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: scoreColor,
          textColor: scoreColor || theme.palette?.neutral[800],
          trailColor:
            theme.palette?.neutral[200] || theme.palette?.neutral[400],
          backgroundColor: '#000',
        })}
      >
        <ScoreTextContainer
          className="circular-progress-bar-child"
          scoreColor={scoreColor}
        >
          <strong>
            <CountUp end={score} duration={0.5} />
            {!isComplete ? '%' : ''}
          </strong>
        </ScoreTextContainer>
      </CircularProgressbarWithChildren>
      <Status isComplete={isComplete} scoreColor={record?.score_color}>
        {statusMessage}
      </Status>
    </ScoreContainer>
  )
}

export default RecordScore
