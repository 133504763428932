import { Divider, Alert } from 'antd'
import useUrlState from '@ahooksjs/use-url-state'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useInterval } from 'ahooks'
import { useTranslation } from 'react-i18next'

import { DeviceCard, RecordResultItem } from '@colonynetworks/ui'

import { useTestDetails, useActive } from 'src/queries'
import { LoadingPage } from 'src/ui-components'
import PoweredBy from 'src/ui-components/PoweredBy'
import RunSignalTest from 'src/Components/Dashboard/Device/RunSignalTest'
import BandwidthCard from './BandwidthCard'
import RecordScore from './RecordScore'

const DetailsContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px 16px 24px;
`

const Detail = () => {
  const { t } = useTranslation()
  const { test_id }: any = useParams()
  const [{ project_uid, serial_number }] = useUrlState()
  const { data, isLoading, refetch } = useTestDetails({
    project_uid,
    serial_number,
    test_id,
  })
  const { data: activeData } = useActive({
    project_uid,
    serial_number,
  })

  const isComplete = data?.status_complete

  const statusMessageArr = data?.status_message.split('.') || []
  const statusMessageKey = statusMessageArr[statusMessageArr.length - 1]
  let statusMessage = t(`signal_test.status.${statusMessageKey}`).cap()

  if (isComplete && data) {
    if (data.score_color === 'green') {
      statusMessage = t('status.passed').cap()
    } else if (data.score_color === 'yellow') {
      statusMessage = t('status.passed_with_caution').cap()
    } else {
      statusMessage = t('status.failed').cap()
    }
  }

  useInterval(() => {
    if (!isComplete) {
      refetch()
    }
  }, 2000)

  if (isLoading) return <LoadingPage title={t('status.loading').cap()} />

  return (
    <>
      {!isComplete && (
        <Alert message={`${statusMessage}...`} type="info" showIcon />
      )}
      <BandwidthCard
        downloadSpeed={data.bw_down}
        uploadSpeed={data.bw_up}
        downloadColor={data.bw_down_color}
        uploadColor={data.bw_up_color}
      />
      <RecordScore record={data} />
      <DetailsContainer>
        <DeviceCard
          active={activeData?.active}
          siteName={data?.site_name}
          serialNumber={serial_number}
        />
        <Divider />
        {data.connection_type && (
          <RecordResultItem
            icon={['fad', 'sim-card']}
            title={t('signal_test.connection_type')}
            value={data.connection_type}
            color={data.connection_type_color}
          />
        )}
        {!isNaN(data.signal) && (
          <RecordResultItem
            icon={['fad', 'signal-alt-3']}
            title={t('signal_test.signal')}
            value={data.signal}
            color={data.signal_color}
            suffix="dB"
          />
        )}
        {!isNaN(data.noise) && (
          <RecordResultItem
            icon={['fad', 'waveform-path']}
            title={t('signal_test.noise')}
            value={data.noise}
            color={data.noise_color}
            suffix="dB"
          />
        )}
        {data.cell_id && (
          <RecordResultItem
            icon={['fad', 'broadcast-tower']}
            title={t('signal_test.cell_id')}
            value={data.cell_id}
            color={'black'}
          />
        )}
        {data.band && (
          <RecordResultItem
            icon={['fad', 'wave-sine']}
            title={t('signal_test.band')}
            value={data.band}
            color={'black'}
          />
        )}
        {!isNaN(data.latency) && (
          <RecordResultItem
            icon={['fad', 'sort-alt']}
            title={t('signal_test.latency')}
            value={data.latency.toFixed(2)}
            color={data.latency_color}
            suffix="ms"
          />
        )}
        {!isNaN(data.packet_loss) && (
          <RecordResultItem
            icon={['fad', 'box-check']}
            title={t('signal_test.packet_loss')}
            value={data.packet_loss}
            color={data.packet_loss_color}
          />
        )}
        {!isNaN(data.jitter) && (
          <RecordResultItem
            icon={['fad', 'wave-square']}
            title={t('signal_test.jitter')}
            value={data.jitter}
            color={data.jitter_color}
            suffix="ms"
          />
        )}
        {!isNaN(data.temp) && (
          <RecordResultItem
            icon={['fad', 'thermometer-half']}
            title={t('signal_test.temp')}
            value={data.temp}
            color={data.temp_color}
            suffix="°C"
          />
        )}
        {isComplete && <Divider />}
        {isComplete && <RunSignalTest />}
      </DetailsContainer>
      <PoweredBy />
    </>
  )
}

export default Detail
