import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from 'src/Components/Layout'
import Login from 'src/Components/Login'
import Dashboard from 'src/Components/Dashboard'
import Detail from 'src/Components/Detail'
import QRLegacyComponent from 'src/Components/QR/Legacy'
import QRComponent from 'src/Components/QR'

import ScrollToTop from './ScrollToTop'

const AppRoutes = () => (
  <Router>
    <Layout>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/test/:test_id" element={<Detail />} />
          <Route
            path="/qr/:project_uid/:serial_number"
            element={<QRComponent />}
          />
          <Route path="/:qr_code" element={<QRLegacyComponent />} />
        </Routes>
      </ScrollToTop>
    </Layout>
  </Router>
)

export default AppRoutes
