import { useQuery } from 'react-query'
import axios from 'axios'

type ParamsType = {
  qr_code: string
}

export const useQRCodeLegacy = (parameters: ParamsType) => {
  return useQuery(
    ['qr_legacy', parameters.qr_code],
    async () => {
      try {
        const result = await axios.get(
          `https://api-signaltest.colonynetworks.com/v4/signaltest/qr_legacy?hostname=${parameters.qr_code}`
        )

        return result.data
      } catch (error) {
        throw new Error('Failed fetching qr_legacy')
      }
    },
    {
      retry: 1,
    }
  )
}
