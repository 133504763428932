import useUrlState from '@ahooksjs/use-url-state'
import { DeviceHistoryChart } from '@colonynetworks/ui'
import { useHistory } from 'src/queries'

const Chart = () => {
  const [{ project_uid, serial_number }] = useUrlState()
  const { data, isLoading } = useHistory({
    project_uid,
    serial_number,
  })

  const records = data?.records || []
  const chartData = records.slice()

  return <DeviceHistoryChart data={chartData} isLoading={isLoading} />
}

export default Chart
